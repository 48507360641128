<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/cv`"
                title="Resume"
                :columns="columns"
                routerpath="/hr/addresume"
                :formOptions="formOptions"
                :newPage="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      viewFields:[
        {
          label: 'Job Vacancy',
          field: 'jobvacancy',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'File',
          field: 'attachment',
          type: 'attachment',
          class: "col-md-4",
        },
        {
          label: 'Status',
          field: 'status',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'At',
          field: 'at',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Taluka',
          field: 'taluka',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Pincode',
          field: 'pincode',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'District',
          field: 'district',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Area',
          field: 'area',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'State',
          field: 'state',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Mobile',
          field: 'phone',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Email',
          field: 'email',
          type: 'text',
          class: "col-md-4",
        },

      ],
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Job Vacancy",
          field: "vacancyid",
          type: "dropdown",
          url: "getjobvacancy",
          dropdownname: 'name',
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Job Vacancy",
          },
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "At",
          field: "at",
          filterOptions: {
            enabled: true,
            placeholder: "Search At",
          },
        },
        {
          label: "Taluka",
          field: "taluka",
          filterOptions: {
            enabled: true,
            placeholder: "Search Taluka",
          },
        },
        {
          label: "District",
          field: "district",
          filterOptions: {
            enabled: true,
            placeholder: "Search District",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search State",
          },
        },
        {
          label: "Mobile",
          field: "phone",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type:'staticdropdown',
          url: 'getUsers',
          dropdownname: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type:'staticdropdown',
          url: 'getUsers',
          dropdownname: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Resume" : "Add Resume"}`,
        submitRouterPath: "/hr/resume",
        usersDropdown: true,
        getEditValue: `${baseApi}/getcvById`,
        branchDropdown: true,
        designationDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/cv`,
        inputFields: [],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Resume") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
